<script lang="ts">
	import { debug } from "../common/debug";
	import { openWebSocket } from "../common/pubsub";
	import { languages } from "./languages";

	let selectedLanguage = languages[0];
	const isLiveMs = 15 * 1000;
	let isLive = false;
	let isLiveTimeout: NodeJS.Timeout;
	let translated = [""];
	let ackId = 0;
	let ws: WebSocket;
	const event = new URLSearchParams(window.location.search).get("event");
	let name = event;
	const prevTranslations = 2;

	const initWebSocket = async () => {
		ws = await openWebSocket(event);
		ws.onmessage = e => {
			debug(`Message received: ${e.data}`);
			const { data } = JSON.parse(e.data);
			if (!!data) {
				isLive = true;
				clearTimeout(isLiveTimeout);
				isLiveTimeout = setTimeout(() => { isLive = false; }, isLiveMs);
				translated = [...translated.slice(-prevTranslations), data];
				setTimeout(() => document.getElementById("bottom").scrollIntoView(), 0);
			}
		};
	};
	initWebSocket();
	
	const subscribe = (language: string) => {
		ws.send(JSON.stringify({
			type: "joinGroup",
			group: `${event}/${language}`,
			ackId
		}));
		ackId++;
		ws.send(JSON.stringify({
			type: "sendToGroup",
			group: `${event}_subscribe`,
			dataType: "text",
			data: `${event}/${language}`,
			ackId
		}));
		ackId++;
	};
	const unsubscribe = (language: string) => {
		ws.send(JSON.stringify({
			type: "sendToGroup",
			group: `${event}_unsubscribe`,
			dataType: "text",
			data: `${event}/${language}`,
			ackId
		}));
		ackId++;
		ws.send(JSON.stringify({
			type: "leaveGroup",
			group: `${event}/${language}`,
			ackId
		}));
		ackId++;
	};

	const changeLanguage = () => {
		const select = document.getElementById("language-select") as HTMLSelectElement;
		const newValue = select.value;
		debug(`Changing language to ${newValue}`);
		if (selectedLanguage !== "none") {
			unsubscribe(selectedLanguage);
		}
		selectedLanguage = newValue;
		if (selectedLanguage !== "none") {
			subscribe(selectedLanguage);
		}
	};

	let theme: 'light' | 'dark' = 'light';

	const toggleTheme = () => {
		theme = (theme === 'light') ? 'dark' : 'light';
	};
</script>

<div class={`page ${theme}`}>
	<div class={`settings ${theme}`}>
		<button on:click={toggleTheme}>{theme === 'dark' ? "🌞" : "🌛"}</button>
	</div>
	<main>
		<h1 class={theme}>{name}</h1>
		<!-- <p>Visit the <a href="https://svelte.dev/tutorial">Svelte tutorial</a> to learn how to build Svelte apps.</p> -->
		<div>
			<div class="selector">
				<select name="language" id="language-select" on:change="{changeLanguage}">
					<option value="none">Select a language</option>
					{#each languages as language}
						<option value="{language}">{language}</option>
					{/each}
				</select>
			</div>
			<div class={theme}>
				Is live? { isLive ? "✅" : "❌" }
			</div>
			<div class={`translated ${theme}`}>
				{#each translated as chunk, id}
					<p><span class={id === translated.length - 1 ? "recent" : ""}>{chunk}</span></p>	
				{/each}
			</div>
			<div id="bottom" />
		</div>
	</main>
</div>

<style>
	.page {
		background-color: white;
		width: 100%;
		height: 100%;
	}

	.page.dark {
		background-color: #2f2f2f;
	}

	.settings {
		position: fixed;
		right: 0;
	}

	.settings button {
		cursor: pointer;
		background-color: #2f2f2f;
		margin: 0.5rem;
	}

	.settings.dark button {
		cursor: pointer;
		background-color: white;
	}

	main {
		text-align: center;
		padding: 1em;
		max-width: 360px;
		margin: 0 auto;
	}

	h1 {
		color: #ff3e00;
		text-transform: uppercase;
		font-size: 2em;
		font-weight: 100;
	}

	h1.dark {
		color: #569cd6;
	}

	.dark {
		color: whitesmoke
	}

	.translated .recent {
		background-color: blanchedalmond;
	}

	.translated.dark .recent {
		background-color: #274660;
	}

	@media (min-width: 640px) {
		main {
			/* max-width: none; */
		}
	}
</style>