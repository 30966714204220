import { serviceUrl } from "./config";
import { debug } from "./debug";
export const openWebSocket = async (event, options) => {
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get("key");
    const response = await fetch(`${serviceUrl}/${event}${(options === null || options === void 0 ? void 0 : options.isHost) ? "/host" : ""}/pubsub/token?key=${key}`, { mode: "cors" });
    const { baseUrl, url } = await response.json();
    const ws = new WebSocket(url, "json.webpubsub.azure.v1");
    ws.onopen = () => {
        debug(`connected to ${baseUrl}`);
        if (options === null || options === void 0 ? void 0 : options.onOpen)
            options.onOpen();
    };
    return ws;
};
